<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_system_log_title') }}</dmx-title>
        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} logs found
                <span class="ml-3">
                    show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(100)" :style="((options.perPage == 100) ? 'font-weight: bold;' : ' ')">100</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
        </b-row>

        <b-table id="system_logs-table"
                 thead-class="dmx-thead-block"
                 :busy="loading"
                 outlined
                 striped
                 bordered
                 hover
                 :items="logEntries"
                 :fields="headers"
                 @row-clicked="viewLogEntry">
        </b-table>

        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="system_logs-table">
            </b-pagination>
        </div>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import logService from '@/services/logService';
    import router from '@/router';
    import { logLevels } from '../../variables/variables.js';
    import moment from 'moment';

    export default {
        name: 'log',
        components: {
        },
        data: () => ({
            loading: false,
            logEntries: [],
            searchFieldIsLoading: false,
            searchField: '',
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            options: {
                calculateWidths: true,
                sortBy: ["createdOnUtc"],
                sortDesc: [true],
                perPage: 10,
                currentPage: 1
            },
            timeoutId: 0,
            bcItems: [
                {
                    text: 'system logs',
                    href: '/log',
                    active: true,
                }
            ]

        }),
        computed: {
            headers() {
                return [
                    {
                        text: this.$t('common_level'),
                        sortable: true,
                        align: 'center',
                        value: 'logLevel',
                        key: 'logLevel',
                    },
                    {
                        text: this.$t('common_time'),
                        sortable: true,
                        width: '180',
                        value: 'createdOnUtc',
                        key: 'createdOnUtc'
                    },
                    {
                        text: this.$t('common_message'),
                        sortable: true,
                        value: 'shortMessage',
                        key: 'shortMessage'
                    },
                    {
                        text: this.$t('common_ip'),
                        sortable: true,
                        width: '150',
                        value: 'ipAddress',
                        key: 'ipAddress'
                    }
                ]
            }
        },
        watch: {
            options: {
                handler() {
                    this.getLogEntries();
                },
                deep: true,
            },
        },
        methods: {
            viewLogEntry(e) {
                router.push({ name: 'viewLogEntry', params: { logId: e.id } })
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getLogEntries();
                }, 1000);
            },
            setPerPage(num) {
                this.options.currentPage = 1;
                this.options.perPage = num;
            },
            getLogEntries() {
                logService.getLogEntries({
                    Count: this.options.perPage,
                    Gates: [],
                    SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage
                })
                    .then((data) => {
                        data.items.map(x => {
                            x.logLevel = logLevels[x.logLevel].name;
                            x.createdOnUtc = moment(x.createdOnUtc).format('YYYY-MM-DD HH:mm:ss');
                        });
                        this.logEntries = data.items;

                        this.searchFieldIsLoading = false;
                        this.options.currentPage = data.page
                        this.pagination.totalListCount = data.totalListCount

                        this.loading = false
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        },
        mounted() {
            this.getLogEntries();
        }
    }
</script>